import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FunctionsMessenger } from '../../sections/functionsMessenger/FunctionsMessenger';
import { Confidentiality } from '../../sections/confidentiality/Confidentiality';
import { Platforms } from '../../sections/platforms/Platforms'
import { FAQ } from '../../sections/faq/Faq'
import { ContactForm } from '../../sections/contactForm/ContactForm'
import { ContactFormWrapper } from '../../sections/contactForm/ContactFormWrapper'
import { ReactComponent as Close } from '../../icons/close.svg'


import './HomePage.css';


export const HomePage = () => {

    const [isPopupForm, setIsPopupForm] = useState(false);
    const [isPopupSubmittedForm, setIsPopupSubmittedForm ] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isVisibleForm, setIsVisibleForm] = useState(false);
    const [isVisibleSubmittedForm, setIsVisibleSubmittedForm] = useState(false);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);

    const toggleBurgerMenu = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };


    const togglePopupForm = () => {
        if(isVisibleForm) {
            setIsVisibleForm(false);
            setTimeout(() => setIsPopupForm(false), 300);
        } else {
            setIsPopupForm(true);
            setTimeout(() => setIsVisibleForm(true), 300);
        }
        
        
    };

    const togglePopupSubmittedForm = () => {
        if (isVisibleSubmittedForm) {
            setIsVisibleSubmittedForm(false);
            setTimeout(() => setIsPopupSubmittedForm(false), 300);
        } else {
            setIsPopupSubmittedForm(true);
            setTimeout(() => setIsVisibleSubmittedForm(true), 300);
        }
    };

    useEffect(() => {
        const handleEscCloseForm = (event) => {
          if (event.key === 'Escape' && isPopupForm) {
            setIsPopupForm(false);
          }
        };

        const handleEscCloseSubmittedForm = (event) => {
            if (event.key === 'Escape' && isPopupSubmittedForm) {
                setIsPopupSubmittedForm(false);
            }
          };
    
        document.addEventListener('keydown', handleEscCloseForm);
        document.addEventListener('keydown', handleEscCloseSubmittedForm);
    
        return () => {
          document.removeEventListener('keydown', handleEscCloseForm);
          document.removeEventListener('keydown', handleEscCloseSubmittedForm);
        };
    }, [isPopupForm, isPopupSubmittedForm]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <div className='main__container'>
            {/* Header */}

            <header className={`header__container ${isScrolled ? 'header__container_scrolled' : ''}`}>
                <div className="header__logo">
                    <svg className={`logo ${isScrolled ? 'logo_scrolled' : ''}`} />
                </div>
                
                <div className={`burger-menu ${isBurgerOpen ? 'open' : ''}`} onClick={toggleBurgerMenu}>
                    <span className="burger-bar"></span>
                    <span className="burger-bar"></span>
                    <span className="burger-bar"></span>
                </div>

                <nav className={`header__nav ${isBurgerOpen ? 'header__nav_open' : ''}`}>
                    <div className='mobile'>
                        <svg className='logo logo_scrolled'/>
                        <div className='burger-menu-open' onClick={toggleBurgerMenu}>
                            <span className="burger-bar"></span>
                            <span className="burger-bar"></span>
                        </div>
                    </div>
                    <ul className="header__list">
                        <li className={`header__item ${isScrolled ? 'header__item_scrolled' : ''}`}>
                            <a href="#missionSectionId" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu}>Преимущества</a>
                        </li>
                        <li className="header__item"><a href="#possibilitiesSectionId" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu} >Возможности</a></li>
                        <li className="header__item"><a href="#platformsSectionId" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu} >Платформы</a></li>
                        <li className="header__item"><a href="#tariffsSectionId" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu}>Тарифы</a></li>
                        <li className="header__item"><a href="#faqSectionId" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu}>Частые вопросы</a></li>
                        {/* <li className="header__item"><Link to="/document" className={`header__link subtitle_L ${isScrolled ? 'header__link_scrolled' : ''}`} onClick={toggleBurgerMenu}>Документация</Link></li> */}
                        <li className="header__item header__item_padding_non">
                            <button className="button button-link-background">
                                <a href="https://chatsoft.ru" target="_blank" className="button-link" rel="noreferrer">
                                    <span className="button-text bodyXS_Bold_18">О компании</span>
                                    <svg className="button-arrow-right-up-line"/>
                                </a>
                            </button>
                            <button className="button button-contact" onClick={() => {togglePopupForm(); toggleBurgerMenu()}}>
                                <span className="button-text bodyXS_Bold_18">Оставить заявку</span>
                                <svg className="button-edit"/>
                            </button>
                        </li>
                    </ul>
                </nav>
            </header>
                
                {/* Hero Section */}
                <section className="hero">
                    <div className='hero__container'>
                        <div className='hero__card hero__card-content'>
                            <div className="hero__content-text_wrapper">
                                <h3>Комплекс <br/>приложений <br/>для защищенного <br/>обмена <br/>информацией</h3>
                                <p className="bodyS_Medium_24">Продукт от Российского разработчика.<br/> 
                                    Зарегистрирован в реестре отечественного ПО. <br/>Надежный, быстрый, современный.
                                </p>
                            </div>
                            <button className="button button-contact" onClick={togglePopupForm}>
                                <span className="button-text bodyXS_Bold_18">Оставить заявку</span>
                                <svg className="button-edit"/>
                            </button>
                        </div>
                        <div className='hero__card hero__card-img'></div>
                        <div className='hero__card hero__card-img'></div>
                        <div className='hero__card hero__card-img'></div>
                    </div>
                </section>

            {/* Mission Section */}
            <section className="mission mission__container" id="missionSectionId">
                    <h2 className="mission__title">Наша миссия — предоставлять</h2>
                    <h2 className="mission__title">самый защищенный и безопасный</h2>
                    <h2 className="mission__title">мессенджер в стране!</h2>
            </section>

            {/* Advantages Section */}
            <section id="advantagesSectionId" className="advantages">
                <div className="advantages__container">
                    <div className="advantages__item">
                        <div className='advantages__img_wrapper'>
                            <img src="/icon_1.svg" alt="" />
                        </div>
                        <div className='advantages_item__text_content'>
                            <h4 className="advantages__title">Безопасная переписка</h4>
                            <div className='advantages__text_content_ul'>
                                <p className='advantages__text_content_title bodyS_Regular_22'>Двухфакторная аутентификация:</p>
                                <p className='advantages__text_content_li bodyS_Regular_22'>Подтверждение регистрации с помощью смс.</p>
                                <p className='advantages__text_content_li bodyS_Regular_22'>PIN-код для входа в аккаунт.</p>
                            </div>
                        </div>
                    </div>
                    <div className="advantages__item">
                        <div className='advantages__img_wrapper'>
                            <img src="/icon_2.svg" alt="" />
                        </div>
                        <div className='advantages_item__text_content'>
                            <h4 className="advantages__title">Протокол шифрования</h4>
                            <div className='advantages__text_content_ul'>
                                <p className='advantages__text_content_li bodyS_Regular_22'>Двойной слой шифрования (алгоритмы Российского шифрования принципа "пользователь пользователь", "пользователь-сервер", "сервер-сервер").</p>
                                <p className='advantages__text_content_li bodyS_Regular_22'>Управление ключами шифрования.</p>
                            </div>
                        </div>
                    </div>
                    <div className="advantages__item">
                        <div className='advantages__img_wrapper'>
                            <img src="/icon_3.svg" alt="" />
                        </div>
                        <div className='advantages_item__text_content'>
                            <h4 className="advantages__title">Полный контроль</h4>
                            <div className='advantages__text_content_ul'>
                                <p className='advantages__text_content_li bodyS_Regular_22'>Собственный корпоративный сервер.</p>
                                <p className='advantages__text_content_li bodyS_Regular_22'>Возможность работать без интернета (внутри локальной сети).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Possibilities Section */}
            <section id="possibilitiesSectionId" className="possibilities">
                <div className="possibilities__container">
                    <div className='possibilities__content'>
                        <div className='col'>
                            <h3>Общение <br/>в диалогах <br/>и группах</h3>
                            <p className='bodyM_Regular_22'>Создавайте чаты в формате один на один или обсуждайте рабочие вопросы 
                                с коллегами в групповых чатах с защищенными алгоритмами безопасности. 
                            </p>
                            <p className='bodyM_Regular_22'>
                                Сохраняйте важную информацию 
                                в чате "Избранное" для себя даже 
                                не имея доступ в интернет.
                            </p>
                            <div className='tag blue captionL_Regular_16'>Функции классического мессенджера </div>
                        </div>
                        <div className='col'><img src="/phone.png" alt="" /></div>
                    </div>
                    <div className='possibilities__content'>
                        <div className='col-2'><img src="/phone-messege.svg" alt="" /></div>
                        <div className='col-2'>
                            <h3>Отправка фото, <br/>видео и голосовых <br/>сообщений</h3>
                            <p className='bodyM_Regular_22'>
                                Делитесь фотографиями, видео 
                                и голосовыми сообщениями с другими пользователями. 
                            </p>
                            <p className='bodyM_Regular_22'>
                                Редактируйте, удаляйте и закрепляйте мультимедиа.
                            </p>
                            <div className='tag cyan captionL_Regular_16'>Функции классического мессенджера </div>
                        </div>

                    </div>

                </div>
            </section>

            {/* Functions-Messenger Section */}
            <FunctionsMessenger/>                        

            {/* Conference-calls Section */}
            <section className="conference-calls">
                    <div className="conference-calls__container">
                        <h3 className="conference-calls__title">Конференц-звонки</h3>
                        <img src="/screen.png" alt="Conference-calls" />
                        <p className="conference-calls__subtitle bodyM_Regular_22">Общайтесь с коллегами не только через сообщения,<br/> 
                        но и через аудио/видео звонки как в личных сообщениях, так и в группах!<br/> Пользуйтесь всеми функциями звонка, оперативно передавая всю важную информацию коллегам.</p>
                    </div>
                </section>

            {/* Confidentiality Section */}
            <Confidentiality/>

            {/*Protect Section*/}                            
            <section className="protect">
               <div className="protect__container">
                    <div className="protect__wrapper">
                        <h3 className="protect__title">Скроем информацию<br/> 
                        от нежелательных лиц</h3>
                        <p className="protect__subtitle bodyM_Regular_22">
                        Благодаря передовым технологиям шифрования,<br/>
                        защищаем своих пользователей
                        от нежелательных ушей и глаз.
                        </p>
                    </div>

                    <div className="protect__contect-list">
                        <img className="contect-list__item" alt="" src="/protect.png"/>
                    </div>
                </div>                     
            </section>

            {/* Registry Section*/}
            <section className="registry">
                <div className="registry__container">
                    <h3 className="registry__title">KeepChat соответствует всем требованиям</h3>
                    <h3 className="registry__title registry__title_underline">импортозамещения</h3>
                    <h4 className="registry__subtitle_s">***</h4>
                    <h4 className="registry__subtitle">
                        Chatsoft на 
                        <h4 className="registry__title registry__title_underline">100% российская компания</h4>
                    </h4>
                    <h4 className="registry__subtitle_s">***</h4>
                    <h4 className="registry__subtitle">
                        Интерфейс и поддержка продукта 
                        только на 
                        <h4 className="registry__title registry__title_underline">русском языке</h4>
                    </h4>

                    <div className="registry__list">
                        <div className="registry__list-wrapper">
                            <div className="registry__item">
                                <div>
                                    <img className="registry__item_padding"src="/reg_1.png" alt="" />
                                </div>
                                <p className="bodyS_Regular_22">Входим в реестр отечественного ПО<br/>(запись №15630 от 25.11.2022)</p>
                            </div>
                            <div className="registry__item">
                                <div>
                                    <img src="/reg_2.png" alt="" />
                                </div>
                                <p className="bodyS_Regular_22">Прошли Государственную регистрацию программ для ЭВМ в Роспатенте. (Свидетельство №2021669714)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Platforms Section */}
            <Platforms/>


            {/* Tariffs Section */}
            <section id="tariffsSectionId" className="tariffs">
                <div className="tariffs__container">
                    <h3 className="tariffs__title">Выберите подходящий тариф</h3>
                    <div className="tariffs__list">
                        <div className="tariffs__item">
                            <div className="tariffs__item-wrapper">

                                <div className="tariffs__name-wrapper">
                                    <h4 className="tariffs__name">Индивидуальный</h4>
                                    <p className="tariffs__name-subtitle">Краткое описание для кого тариф.</p>
                                </div>

                                <ul className="tariffs__features-list">
                                    <li className="bodyM_Regular_22">Двухфакторная авторизация.</li>
                                    <li className="bodyM_Regular_22">Приложение находится на нашем защищённом сервере.</li>
                                    <li className="bodyM_Regular_22">Установка происходит по одноразовой ссылке.</li>
                                    <li className="bodyM_Regular_22">Функции классического мессенджера:
                                        <ul className="tariffs__features-sublist">
                                            <li className="bodyM_Regular_22">- Текстовые сообщения</li>
                                            <li className="bodyM_Regular_22">- Аудио и видеозвонки</li>
                                            <li className="bodyM_Regular_22">- Звуковые сообщения</li>
                                            <li className="bodyM_Regular_22">- Групповые чаты</li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>
                            <button className="button tag blue" onClick={togglePopupForm}>
                                    <span className="button-text bodyXS_Bold_18">Оставить заявку</span>
                                    <svg className="button-edit"/>
                            </button>
                        </div>
                        <div className="tariffs__item">
                            <div className="tariffs__item-wrapper">
                                <div className="tariffs__name-wrapper tariffs__name-wrapper_background-white">
                                    <h4 className="tariffs__name">Компания</h4>
                                    <div className="tariffs__name-info tag blue">Для юрлиц</div>
                                    <p className="tariffs__name-subtitle">Краткое описание для кого тариф.</p>
                                </div>

                                <ul className="tariffs__features-list color-wite">
                                    <li className="bodyM_Regular_22">Двухфакторная авторизация.</li>
                                    <li className="bodyM_Regular_22">Приложение на корпоративном сервере.</li>
                                    <li className="bodyM_Regular_22">Установка по одноразовой ссылке с подтверждением принадлежности через корпоративную почту.</li>
                                    <li><p className="bodyM_Regular_22">Функции классического мессенджера</p>
                                        <ul className="tariffs__features-sublist">
                                            <li className="bodyM_Regular_22">- Текстовые сообщения;</li>
                                            <li className="bodyM_Regular_22">- Аудио и видеозвонки;</li>
                                            <li className="bodyM_Regular_22">- Звуковые сообщения;</li>
                                            <li className="bodyM_Regular_22">- Групповые чаты.</li>
                                        </ul>
                                    </li>
                                    <li className="bodyM_Regular_22">Расширенный функционал:
                                        <ul className="tariffs__features-sublist">
                                            <li className="bodyM_Regular_22">- Отправка фото и видео для однократного просмотра;</li>
                                            <li className="bodyM_Regular_22">- Исчезающие сообщения;</li>
                                            <li className="bodyM_Regular_22">- Очистка истории чата в один клик</li>
                                        </ul>
                                    </li>
                                    <li className="bodyM_Regular_22">Контроль: полная блокировка пользователя с запретом повторной регистрации.</li>
                                </ul>

                            </div>
                            <button className="button button-contact" onClick={togglePopupForm}> 
                                    <span className="button-text bodyXS_Bold_18">Оставить заявку</span>
                                    <svg className="button-edit"/>
                            </button>

                        </div>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}

            <FAQ/>

            {/* Details Section */}
             <section className="details">
                <div className="details__container">
                    <div className="details__container-wrapper">
                        <h3 className="details__title">Нужно больше подробностей?</h3>
                        <p className="details__subtitle bodyM_Regular_22">Получите презентацию KeepChat с более подробным описанием продукта или оставьте заявку на получение доступа к приложению.
                        </p>
                    </div>
                    <div className="details__buttons">
                    <button className="button button-link-background">
                        <a href="/presentation-Kipchat.pdf" download="KeepChat_presentation" className="button-link" rel="noreferrer">
                            <span className="button-text bodyXS_Bold_18">Скачать презентацию</span>
                            <svg className="button-download"/>
                        </a>
                    </button>
                    <button className="button button-contact" onClick={togglePopupForm}>
                        <span className="button-text bodyXS_Bold_18">Оставить заявку</span>
                        <svg className="button-edit"/>
                    </button>
                    </div>
                </div>
            </section>

            {/*  Contact-form Section */}
            <ContactFormWrapper>
                <ContactForm/>
            </ContactFormWrapper>
            

            {/* Footer */}
            <footer className="footer">
                <div className="footer__container">
                    <h3 className="footer__title">KeepChat</h3>
                    <p className="footer__subtitle bodyM_Regular_22">Комплекс приложений для защищенного обмена информацией</p>
                    <div className="footer__content">
                        <div className="footer__content-info">
                            <p className="footer__text bodyS_Regular_22">ООО «Чатсофт», 2024 год.</p>
                            <p className="footer__text bodyS_Regular_22">info@chatsoft.ru </p>
                            <p className="footer__text bodyS_Regular_22">+7 (926) 146-59-04  </p>
                            <p className="footer__text bodyS_Regular_22">115191, Москва г., Духовской пер., дом 17, стр. 15, 
                            <br/>офис 138</p>
                        </div>
                        <div className="footer__content-link">
                            <span className="footer__link bodyS_Regular_22"><Link  to="/user-agreement" className="footer__link">Пользовательское соглашение</Link></span>
                            <span className="footer__link bodyS_Regular_22"><Link  to="/privacy-policy" className="footer__link">Политика конфиденциальности</Link></span>
                            {/* <span className="footer__link bodyS_Regular_22"><Link  to="/document" className="footer__link">Документация</Link></span> */}
                            <a href="https://chatsoft.ru" target="_blank" className="footer__link bodyS_Regular_22" rel="noreferrer">
                                <span className="bodyS_Regular_22" rel="noreferrer" >Сайт “Chatsoft”</span>
                            </a> 
                        </div>
                    </div>         
                </div>
            </footer>

            {isPopupForm && (
                <div className={`popup`}>
                    <div className={`popup__content ${isVisibleForm ? 'popup_visible' : ''}`}>
                        <button className="popup__close" onClick={togglePopupForm}>
                                <Close/>
                        </button>
                            <h4 >Оставить заявку</h4>
                            <p className="popup__subtitle bodyS_Regular_22">Получите консультацию по внедрению KeepChat в вашей компании.</p>
                            <ContactForm togglePopupSubmittedForm={togglePopupSubmittedForm} />
                    </div>
                </div>
            )}
            {isPopupSubmittedForm && (
                <div className={`popup`}>
                    <div className={`popup__content ${isVisibleSubmittedForm ? 'popup_visible' : ''}`}>
                        <button className="popup__close" onClick={togglePopupSubmittedForm}>
                                <Close/>
                        </button>
                            <h3 >Ваша заявка отправлена</h3>
                            <p className="popup__subtitle bodyM_Regular_22">Мы свяжемся с Вами в ближайшее время.</p>
                            <button className="contact-form__button" onClick={togglePopupSubmittedForm}>
                                <span className="bodyXS_Bold_18">Хорошо</span>
                            </button>
                    </div>
                </div>
            )}
        </div>
    )
}