import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './UserAgreementPage.css'


export const UserAgreementPage = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="user-agreement__main">
            <header className="header__container">
                <div className="header__logo"><svg className="logo_blue"/></div>
  
                <button className="button button-contact">
                    <span className="button-text"><Link  to="/" className="header__link">Назад</Link></span>
                </button>
            </header>
            <section className="user-agreement__container">
                <h3 className='user-agreement__title'>Пользовательское соглашение</h3>
                <div className='user-agreement__content'>
                    <h4 className='user-agreement__subtitle'>Пользовательское соглашение сайта</h4>

                <p className='bodyS_Regular_22 underline' >
                    1. Общие положения Пользовательского соглашения<br/>
                    2. Условия пользования по Соглашению<br/>
                    3. Лицензия на использование Сайта и допустимое использование Сервиса<br/>
                    4. Гарантии Пользователя по Соглашению<br/>
                    5. Лицензия на использование пользовательского контента<br/>
                    6. Ограничения использования<br/>
                    7. Уведомления и рассылка<br/>
                </p>
                <br/>
                <br/>
                <br/>
                <p className='bodyS_Regular_22' >
                    1. Общие положения Пользовательского соглашения<br/>

                    1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон применяются следующие термины и определения:<br/>

                    а) Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;<br/>

                    б) Пользователь — дееспособное физическое лицо, присоединившееся к настоящему Соглашению в собственном интересе либо выступающее от имени и в интересах представляемого им юридического лица.<br/>

                    в) Сайт Администрации/ Сайт — интернет-сайты, размещенные в домене ________.ru и его поддоменах.<br/>

                    г) Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с использованием Платформы.<br/>
                    д) Соглашение - настоящее соглашение со всеми дополнениями и изменениями.<br/>
                    </p>
                </div>
            </section>
        </div>
    )
}