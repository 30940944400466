import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DocumentPage.css'

export const DocumentPage = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="document__main">
            <header className="header__container">
                <div className="header__logo"><svg className="logo_blue"/></div>
    
                <button className="button button-contact">
                    <span className="button-text"><Link  to="/" className="header__link">Назад</Link></span>
                </button>
            </header>
            <section className="document__container">
                <h1 className='document__title'>Документация</h1>
                <div className='document__content'>
                    <p>Информация о компании</p>
                    <p>Тарифы</p>
                    <p>Правообладание ПО</p>
                    <p>Юридическая документация</p>
                    <p>Стек технологий</p>
                </div>
            </section>
        </div>
    )
}